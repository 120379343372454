import { Link } from 'gatsby';
import * as React from 'react';

const NotFoundPage = () => (
	<div className="centered">
		<h1 className="mb-2 text-4xl">404: Not Found</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>

		<Link to="/">Back to Main</Link>
	</div>
);

export default NotFoundPage;
